import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import './ContentContainer.css';

const sentences: string[] = [
  'No',
  'I have something planned that day',
  'I will have to get back to you',
  'Not in a million years',
  'Not even if it was the last thing on Earth',
  'I respectfully decline',
  'Are you serious? Or is this some kind of a joke?',
  'Not now.',
  "Look! Squirrel! (This was the best NO suggestion I received from a commentor on my earlier post. I can't wait to use it.)",
  'My word of the year is REST, so I can’t fit another thing in.',
  'Nope.',
  'No thanks, I won’t be able to make it.',
  'Not this time.',
  'Heck no.',
  'No way, Jose. (Since my husband is named Jose, this is a favorite in our house.)',
  "Regrettably, I'm not able to.",
  "It's that time of the year when I must say no.",
  'It\'s a Wednesday. I have a "No on Wednesday" policy.',
  'Ask me in a year.',
  "I know someone that might be a fit for that. I'll email you their information.",
  "You're so kind to think of me, but I can't.",
  'Maybe another time.',
  'Sounds great, but I can’t commit.',
  'Rats! Would’ve loved to.',
  'I’m slammed.',
  'Perhaps next season when things clear up.',
  'I’m at the end of my rope right now so have to take a raincheck.',
  'If only it worked.',
  'I’ll need to bow out.',
  'I’m going to have to exert my NO muscle on this one.',
  'I’m taking some time.',
  'Thanks for thinking of me, but I can’t.',
  'I’m in a season of NO.',
  'I’m not the girl for you on this one.',
  'I’m learning to limit my commitments.',
  'I’m not taking on new things.',
  'Another time might work.',
  'It doesn’t sound like the right fit.',
  "I'm RESTing right now.",
  'I’m not sure I’m the best for it.',
  'No thank you, but it sounds lovely.',
  'It sounds like you’re looking for something I’m not able to give right now.',
  'I believe I wouldn’t fit the bill, sorry.',
  'It’s not a good idea for me.',
  'Not now.',
  'I’m trying to cut back.',
  'I won’t be able to help.',
  'If only I had a clone!',
  'I’m not able to set aside the time needed.',
  'I won’t be able to dedicate the time I need to it.',
  'I’m head-down right now on a project, so won’t be able to.',
  'I wish there were two of me!',
  'I’m honored, but can’t.',
  'NoNoNoNoNoNo.',
  'I’m booked into something else.',
  'I’m not able to make that time.',
  'Thanks, but no thanks.',
  'I’m not able to make it this week/month/year.',
  'Bye now.',
  'I’ve got too much on my plate right now.',
  'I’m not taking on anything else right now.',
  'Bandwidth is low, so I won’t be able to make it work.',
  'I wish I could make it work.',
  'Not possible.',
  'I wish I were able to.',
  'If only I could!',
  'I’d love to — but can’t.',
  'Darn! Not able to fit it in.',
  'Nah.',
  'No thanks, I have another commitment.',
  'Unfortunately, it’s not a good time.',
  'Sadly I have something else.',
  'Unfortunately not.',
  'I have something else. Sorry.',
  'Apologies, but I can’t make it.',
  'Thank you so much for asking. Can you keep me on your list for next year?',
  'I’m flattered you considered me, but unfortunately I’ll have to pass this time.',
  'And my favorite, “Oh I wish I could help but you know, I bet Claire could help you with that.”',
  'Thank you for thinking of me. Unfortunately it’s just not a match.',
  'No, sorry, that’s not really my thing',
  'Can I get back to you on that?',
  'Ew.',
  'No, I’d rather you didn’t, but thanks anyway',
  "I can't make it work.",
  "It just won't fit right now.",
  "I'm really buckling down on my priorities right now, so I can't.",
  'No say I.',
  "What's the compensation?",
  'How much are you able/willing to pay',
  'Ick.',
  'Are you able to better that offer?',
  'Sorry, no can do.',
  "I only say yes to very select opportunities, and unfortunately this doesn't meet my criteria.",
  'The demands would be too much for me.',
  "It's not feasible for me to take this on.",
  'I wish I had all the time in the world.',
  'My body double can.',
  'In another life.',
  'I cry, but decline.',
  "My advisors won't agree to it.",
  'My body says yes, but my heart says No.',
  "I'm not the person you're looking for.",
  "I don't have an iota of bandwidth left in my brain.",
  'If only.',
  'N to the O.',
  'NO.'
];

function ContentContainer() {
  const [sentence, setSentence] = useState(sentences[Math.floor(Math.random() * sentences.length)]);
  const [totalSentences] = useState(sentences.length);

  useEffect(() => {
    console.log('useEffect');

    return () => {
      console.log('unmounting ContentContainer');
    };
  }, [sentence]);

  return (
    <div className="grid-container">
      <div className="Sentence">{sentence}</div>
      <div className="Total">
        Currently{' '}
        <strong>
          <CountUp end={totalSentences} />
        </strong>{' '}
        different ways to say NO and growing!
      </div>
      <button onClick={() => setSentence(sentences[Math.floor(Math.random() * sentences.length)])}>
        Click me
      </button>

      <div>AD</div>
    </div>
  );
}

export default ContentContainer;
